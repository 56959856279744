import { toggleBurger } from './toggleBurger';
import { toggleForm } from './toggleForm';
import { toggleMenu } from './toggleMenu';

//rename to   handleHeader
export const includeHeaderHandlers = () => {
  const header = document.querySelector('.header');

  toggleForm();

  // TODO run it on mobile only
  toggleBurger(header);
  toggleMenu();
};
