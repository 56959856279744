import gsap from 'gsap';
import ScrollMagic from 'scrollmagic';

const defaultOptions = {
  shift: '20%',
  duration: 0.7,
  triggerHook: 'onEnter',
};

export const meetBlocks = (
  trigger,
  selectorA,
  selectorB,
  options = defaultOptions
) => {
  const opts = { ...defaultOptions, ...options };

  const controller = new ScrollMagic.Controller();

  const [nodeA, nodeB] = [selectorA, selectorB].map((sel) => {
    return typeof sel === 'string' ? document.querySelector(sel) : sel;
  });

  console.log(nodeA, nodeB);

  if (!nodeA || !nodeB) {
    console.error({ nodeA, nodeB });
    throw new Error('[meetBlocks] no nodes found for provided selector(s)');
  }

  const tlA = gsap.timeline();
  tlA.fromTo(
    nodeA,
    { opacity: 0.1, x: `-${opts.shift}`, ease: 'circle' },
    { opacity: 1, x: 0, duration: opts.duration }
  );

  const tlB = gsap.timeline();
  tlB.fromTo(
    nodeB,
    { opacity: 0.1, x: opts.shift, ease: 'circle' },
    { opacity: 1, x: 0, duration: opts.duration }
  );

  new ScrollMagic.Scene({
    triggerElement: trigger,
    duration: 0,
    triggerHook: opts.triggerHook,
    offset: 20,
    reverse: false
  })
    .setTween(tlA)
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: trigger,
    duration: 0,
    triggerHook: opts.triggerHook,
    offset: 20,
    reverse: false
  })
    .setTween(tlB)
    .addTo(controller);
};
