import { FormWithValidation } from './FormWithValidation';

const initialState = {
  name: '',
  email: '',
  message: '',
  isAgreed: false,
};

const validator = {
  name: {
    isRequired: {
      value: true,
      errorText: 'Name is required',
    },
    minLength: {
      value: 2,
      errorText: 'Name should have al least 2 characters',
    },
    maxLength: {
      value: 49,
      errorText: 'Name should not exceed 50 characters',
    },
  },

  email: {
    isRequired: {
      value: true,
      errorText: 'Email is required',
    },
    matcher: {
      test: (value) => /\S+@\S+\.\S+/.test(value),
      errorText: 'Email is invalid',
    },
  },

  isAgreed: {
    isRequired: {
      value: true,
      errorText: '',
    },
  },
};

const toggleError = (target, hasError, errorText) => {
  const wrapper = target.closest('.input-labeled');
  const errorTextEl = wrapper.querySelector('.input-labeled__error-text');
  wrapper.classList[hasError ? 'add' : 'remove']('input-labeled--error');
  errorTextEl.innerText = hasError ? errorText : '';
};

export const applyContactForm = ({ inputs, agreedCheckbox, submitBtn }) => {
  const formState = new FormWithValidation(initialState, validator, {
    onErrorStatusChange: (hasError) => {
      submitBtn.disabled = hasError;
    },
  });

  formState.validateAll();

  agreedCheckbox.addEventListener('change', ({ target }) => {
    formState.set(target.name, target.checked);
    formState.validateOne(target.name);
  });

  inputs.forEach((inp) => {
    const validate = (target) => {
      const { hasError, errorText } = formState.validateOne(target.name);
      toggleError(target, hasError, errorText);
    };

    inp.addEventListener('blur', ({ target }) => {
      inp.value = formState.trimOne(target.name);
      validate(target);
    });
    inp.addEventListener('input', ({ target }) => {
      formState.set(target.name, target.value);
      validate(target);
    });
  });

  return formState;
};
