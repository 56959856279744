export const applyInputsLabeled = () => {
  const wrappers = document.querySelectorAll('.input-labeled');

  wrappers.forEach((wrapper) => {
    const handleFocus = () => {
      wrapper.classList.add('input-labeled--fill');
    };

    const handleBlur = ({ target }) => {
      if (!target.value) {
        wrapper.classList.remove('input-labeled--fill');
      }
    };

    const input = wrapper.querySelector('.input-labeled__input');

    input.addEventListener('focus', handleFocus);
    input.addEventListener('blur', handleBlur);
  });
};
