export const toggleForm = () => {
  const openFormButtons = Array.from(
    document.querySelectorAll('.write-us-button')
  );
  const closeFormButton = document.getElementById('close-button');
  const form = document.getElementById('modal-form');
  const dropdown = document.getElementById('dropdown');
  const burger = document.getElementById('burger');

  openFormButtons.forEach((btn) => {
    btn.addEventListener('click', () => {
      document.documentElement.style.overflowY = 'hidden';
      document.body.style.overflowY = 'hidden';
      form.classList.add('modal-form--active');
      dropdown.classList.remove('header__dropdown-wrapper--active');
      burger.classList.remove('header__burger--active');
    });
  });

  closeFormButton.addEventListener('click', () => {
    document.documentElement.style.overflowY = '';
    document.body.style.overflowY = '';
    form.classList.remove('modal-form--active');
  });

  form.addEventListener('click', (event) => {
    if (!event.target.closest('.modal-form__wrapper')) {
      document.documentElement.style.overflowY = '';
      document.body.style.overflowY = '';
      form.classList.remove('modal-form--active');
    }
  });
};
