export const toggleBurger = (header) => {
  const burger = document.getElementById('burger');
  const dropdown = document.getElementById('dropdown');

  burger.addEventListener('click', () => {
    burger.classList.toggle('header__burger--active');
    dropdown.classList.toggle('header__dropdown-wrapper--active');
    header.classList.toggle('header--dark');
  });
};
