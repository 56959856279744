const apiUrl = "https://api.brevo.com/v3/smtp/email";
const apiKey =
  "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";

export const sendMail = async (formFields) => {
  const emailData = {
    sender: {
      name: formFields.name,
      email: formFields.email,
    },
    to: [
      {
        email: "willstrand@protonmail.com",
        name: "Janextos Trading",
      },
    ],
    subject: "Feedback request from " + formFields.name,
    htmlContent: `
      <html>
        <head></head>
        <body>
          <p>Got feedback request from:</p>
          Name: <strong>${formFields.name}</strong> <br />
          Email: <strong>${formFields.email}</strong> <br />
          Message: "<strong>${formFields.message}</strong>"
        </body>
      </html>
      `,
  };

  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "api-key": apiKey,
      "content-type": "application/json",
    },
    body: JSON.stringify(emailData),
  };

  const res = await fetch(apiUrl, options);
  return await res.json();
};
