import "./scripts/setupScrollMagicPlugins";
import { applyContactForm } from "./scripts/forms/applyContactForm";
import { applyInputsLabeled } from "./scripts/forms/inputLabeled";
import gsap from "gsap";
import { meetBlocks } from "./scripts/animations/meetBlocks";
import { includeHeaderHandlers } from "./scripts/header/includeHeaderHandlers";
import "toastify-js/src/toastify.css";
import ScrollMagic from "scrollmagic";
import Toastify from "toastify-js";
import { sendMail } from "./scripts/sendMail";

applyInputsLabeled();
includeHeaderHandlers();

document.querySelectorAll(".form").forEach((form) => {
  const inputs = form.querySelectorAll(".form-input");
  const agreedCheckbox = form.querySelector('[name="isAgreed"]');
  const submitBtn = form.querySelector(".btn");

  const formState = applyContactForm({ inputs, agreedCheckbox, submitBtn });

  form.addEventListener("submit", async (event) => {
    event.preventDefault();

    const resetForm = () => {
      formState.reset();
      inputs.forEach((inp) => (inp.value = ""));
    };

    try {
      submitBtn.disabled = true;
      await sendMail(formState.fields);

      Toastify({
        text: "Request has sent. We`ll contact you shortly",
        duration: 5000,
        gravity: "top",
        position: "center",
        style: {
          background: "#327f55",
        },
      }).showToast();

      resetForm();
      form.closest(".modal-form")?.classList.remove("modal-form--active");
    } catch (error) {
      Toastify({
        text: "Error. Please, try later",
        duration: 300000,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
        style: {
          background: "#a72121",
        },
      }).showToast();
      console.log("Error", error);
    } finally {
      submitBtn.disabled = false;
    }
  });
});

window.addEventListener("DOMContentLoaded", () => {});

window.addEventListener("load", () => {
  animatePage();
});

const animatePage = () => {
  const tl = gsap.timeline();

  tl.from(".header", { duration: 0.5, y: "-100%", clearProps: true })
    .fromTo(
      ".header__header-wrapper",
      { opacity: 0 },
      { opacity: 1, duration: 0.3, clearProps: true }
    )
    .from(document.querySelector(".banner__text:nth-child(1)"), {
      duration: 0.6,
      opacity: 0,
      x: "100%",
      clearProps: true,
    })
    .from(document.querySelector(".banner__text:nth-child(2)"), {
      duration: 0.6,
      opacity: 0,
      x: "-100%",
      clearProps: true,
    });

  meetBlocks(".industries__title", ".industries__title", ".industries__text");

  const controller = new ScrollMagic.Controller();
  new ScrollMagic.Scene({
    triggerElement: ".mission__wrapper",
    duration: 0,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(
      gsap.from(".mission__img", {
        duration: 0.7,
        opacity: 0,
        y: "30%",
      })
    )
    .addTo(controller);

  const timeline = () => {
    const tl = gsap.timeline();
    document.querySelectorAll(".industries__item").forEach((it) => {
      tl.from(it, { opacity: 0, duration: 0.33, scale: 0 }, "-=0.160");
    });
    return tl;
  };

  new ScrollMagic.Scene({
    triggerElement: ".industries__item",
    duration: 0,
    triggerHook: "onCenter",
    reverse: false,
  })
    .setTween(timeline())
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: ".mission__text",
    duration: 0,
    triggerHook: 0.95,
    reverse: false,
  })
    .setTween(
      gsap.from(".mission__title", {
        y: "50%",
        duration: 0.8,
        clearProps: true,
      })
    )
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: ".mission__text",
    duration: 0,
    triggerHook: 0.8,
    reverse: false,
  })
    .setTween(
      gsap.from(".mission__info", {
        y: "100px",
        duration: 0.8,
        clearProps: true,
      })
    )
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: ".ticker__title",
    duration: 0,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(
      gsap.from(".ticker__title", {
        y: "40px",
        duration: 0.8,
        clearProps: true,
      })
    )
    .addTo(controller);

  for (let i = 0; i <= 6; i++) {
    const els = document.querySelectorAll(".advantages-anim-" + i);

    els.forEach((el) => {
      new ScrollMagic.Scene({
        triggerElement: els[0],
        duration: 0,
        triggerHook: "onEnter",
        reverse: false,
      })
        .setTween(
          gsap.from(el, {
            y: "120px",
            duration: 0.77,
            opacity: 0,
            clearProps: true,
          })
        )
        .addTo(controller);
    });
  }

  new ScrollMagic.Scene({
    triggerElement: ".contacts__title",
    duration: 0,
    triggerHook: "onEnter",
    reverse: false,
  })
    .setTween(
      gsap.from(".contacts__title", {
        y: "100px",
        duration: 0.8,
        opacity: 0,
        clearProps: true,
      })
    )
    .addTo(controller);

  new ScrollMagic.Scene({
    triggerElement: ".contacts__form",
    duration: 0,
    triggerHook: 0.65,
    reverse: false,
  })
    .setTween(
      gsap.from(".contacts__form", {
        x: "50%",
        duration: 0.8,
        opacity: 0,
        clearProps: true,
      })
    )
    .addTo(controller);
};
