export const toggleMenu = () => {
  const subMenuList = Array.from(
    document.querySelectorAll('div.header__item-subdropdown')
  );

  subMenuList.forEach((el) => {
    el.addEventListener('click', (event) => {
      const li = event.target.closest('.header__dropdown-item');

      subMenuList.forEach((item) => {
        const currLi = item.closest('.header__dropdown-item');
        const operationName = li === currLi ? 'toggle' : 'remove';
        currLi.classList[operationName]('header__dropdown-item--active');
      });
    });
  });
};
