export class Form {
  fields = {};

  constructor(fields) {
    this.fields = fields;
  }

  _validateFieldName(fieldName) {
    if (!(fieldName in this.fields)) {
      console.error(
        `there is no "${fieldName}" in ${Object.keys(this.fields)}`
      );
      throw new Error('no such field!');
    }
  }

  set(fieldName, value) {
    this._validateFieldName(fieldName);
    this.fields[fieldName] = value;
  }

  reset() {
    this.fields = {};
  }

  trimOne(fieldName) {
    this._validateFieldName(fieldName);
    return (this.fields[fieldName] = this.fields[fieldName].trim());
  }

  get currStateLog() {
    return JSON.stringify(this.fields, null, 2);
  }
}
